<template>
    <div class="edit-page-more common-form-main">
        <div id="J_studentInfo_more_info" class="common-form-layout--scroll">
            <div v-if="selectedTemplate > -1" class="common-form-view">
                <div class="title">更多信息</div>
                <stu-info-fill-main
                    :key="selectedTemplate"
                    :mode="templateList[selectedTemplate].mode"
                    :school-id="schoolId"
                    :stu-basic-info="oriBasicForm"
                    :template-info="templateList[selectedTemplate]"
                />
            </div>
        </div>
        <!-- <div class="btn-container">
            <el-button
                type="primary"
                :loading="submitFormLoading"
            >确定</el-button>
        </div> -->
    </div>
</template>

<script>
import StuInfoFillMain from "./stuInfoTemplate/FillMain.vue"

export default {
    name: 'EditPageMore',
    components: {
        StuInfoFillMain
    },
    props: [
        'pageType',
        'schoolId',
        'oriBasicForm',
        'templateList',
        'selectedTemplate'
    ],
}
</script>

<style lang="scss" scoped>
.edit-page-more {
    position: relative;

    .common-form-view {
        text-align: left;
    }

    .title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        text-align: center;
    }

    /* .btn-container {
        position: absolute;
        bottom: -15px;
        width: 960px;
        height: 54px;
        background: #FFFFFF;
        box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.1);
        line-height: 54px;
        text-align: center;
    } */
}

.common-form-layout--scroll::-webkit-scrollbar {
    width: 0 !important
}
.common-form-layout--scroll {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
</style>
