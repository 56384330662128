<template>
    <div class="edit-page-base common-form-main common-form-layout--scroll summary-teacher-form">
        <div class="common-form-view">
            <div class="basic">
                <el-form
                    :model="basicForm"
                    :rules="basicRules"
                    ref="basicForm"
                    label-position="right"
                    label-width="120px"
                    :disabled="pageType == 'view'">
                    <div class="basic-form">
                        <form-main-title id="J_studentInfo_basic_info" title="基础信息"></form-main-title>

                        <el-form-item style="margin-top: 13px" label="头像" prop="stuImg">
                            <div class="avatar-upload-wrap">
                                <div>
                                    <el-upload
                                        ref="avatarUpload"
                                        action="#"
                                        accept=".jpg, .jpeg, .bmp, .gif, .png"
                                        :show-file-list="false"
                                        :auto-upload="false"
                                        :on-change="handleFileChange"
                                        class="avatar-upload"
                                    >
                                        <div v-if="basicForm.stuImg" class="avatar-upload-img" @click.stop>
                                            <img :src="basicForm.stuImg" alt="">
                                            <div class="avatar-upload-img-operate">
                                                <i class="el-icon-upload2" @click.stop="handleUpload"></i>
                                                <i class="el-icon-delete" @click.stop="handleDelete"></i>
                                            </div>
                                            <div 
                                                class="status-wrap"
                                                :style="{
                                                    background: getValueByState('backGroundColor'),
                                                    color:getValueByState('textColor')
                                                }"
                                            >
                                                <i 
                                                    class="font_family"
                                                    :class="[getValueByState('icon')]"
                                                ></i>
                                                {{ getValueByState('name') }}
                                            </div>
                                        </div>
                                        <i v-else class="avatar-upload-icon el-icon-plus"></i>
                                    </el-upload>
                                    <div 
                                        v-if="basicForm.stuImg && headImgInfo.auditState == '2'"
                                        class="error-tip"
                                        @click="handleErrorTipClick"
                                    >   
                                        <div class="error-tip-text">{{headImgInfo.tip}}</div>
                                        <i class="el-icon-warning-outline"></i>
                                        
                                    </div>
                                </div>
                                
                                <div class="tips-wrap">
                                    <div class="tips-title">
                                        <div class="title">照片上传要求：</div>
                                        <div
                                            v-if="pageType == 'edit'"  
                                            class="tip-text"
                                        ><i class="el-icon-warning-outline"></i>更换头像图片后，系统将会自动保存更改</div>
                                    </div>
                                    <div class="tips-content">1、照片背景选择白色为最佳，保证人脸光照足够、受光均匀，人脸面部图像清晰；</div>
                                    <div class="tips-content">2、请保证上传的照片为正面照片，拍照范围为人员的肩膀及以上；</div>
                                    <div class="tips-content">3、人脸没有其他物体遮挡，如佩戴眼镜请保证眼镜框没有遮挡眼睛，请不要佩戴口罩拍照；</div>
                                    <div class="tips-content">4、上传的照片必须是手机原相机拍照，具有真实性，不要美图、P图等，不要带美颜或者其他特效的照片；</div>
                                    <div class="tips-content">5、支持格式为JPG，JPEG，GIF，BMP或PNG大小不超过20M。</div>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item v-has-permi="['studentBase:uploadFace']" label="上传云端人脸" prop="isNeedFaceLibrary">
                            <el-radio
                                v-model="basicForm.isNeedFaceLibrary"
                                label="1"
                            >是</el-radio>
                            <el-radio
                                v-model="basicForm.isNeedFaceLibrary"
                                label="2"
                            >否</el-radio>
                        </el-form-item>

                        <div class="basic-info">
                            <el-form-item label="姓名" prop="studentName">
                                <el-input
                                    class="input-width-small"
                                    v-model="basicForm.studentName"
                                    :disabled="isHasStu && basicForm.studentName !== ''"
                                    placeholder="请输入"
                                ></el-input>
                            </el-form-item>

                            <el-form-item label="性别" prop="sex">
                                <el-radio
                                    v-model="basicForm.sex"
                                    :disabled="isHasStu"
                                    label="1"
                                >男</el-radio>
                                <el-radio
                                    v-model="basicForm.sex"
                                    :disabled="isHasStu"
                                    label="2"
                                >女</el-radio>
                            </el-form-item>

                            <el-form-item label="身份证号">
                                <el-input
                                    class="input-width-small"
                                    v-model="basicForm.idCard"
                                    :disabled="isHasStu && basicForm.idCard !== ''"
                                    placeholder="请输入"
                                ></el-input>
                            </el-form-item>

                            <el-form-item label="班级" prop="organId">
                                <Cascader
                                    class="class-cascader input-width-small"
                                    :data="item"
                                    @changeCascader="changeCascader"
                                />
                                <!-- <span class="form-value-show-only">{{ basicForm.organName }}</span> -->
                            </el-form-item>

                            <el-form-item label="学号">
                                <el-input
                                    class="input-width-small"
                                    v-model="basicForm.studyNo"
                                    placeholder="请输入"
                                    @change="stuNoChange"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="唯一号" prop="onlyCode">
                                <el-input
                                    class="input-width-small"
                                    v-model="basicForm.onlyCode"
                                    :disabled="pageType === 'edit'"
                                    placeholder="请输入"
                                ></el-input>
                                <div class="onlyCodeTip" v-if="pageType != 'edit' && Object.keys(OnlyNoMax).length > 0">
                                    <img :src="des" alt="">
                                    <el-tooltip  effect="dark" :content="OnlyNoMax.onlyCode" placement="top-start">
                                        <span v-if="OnlyNoMax.hasStudent === '1'" class="OnlyNoMaxSty">该校当前最大值：{{ OnlyNoMax.onlyCode }}</span>
							        </el-tooltip>

                                    <span v-if="OnlyNoMax.hasStudent === '0'">该校当前没有学生</span>
                                </div>
                            </el-form-item>
                            <!-- :disabled="pageType === 'edit' && basicForm.studyNo" -->
                            <el-form-item label="消费账号" prop="consumNumber">
                                <el-input
                                    class="input-width-small"
                                    v-model="basicForm.consumNumber"
                                    :disabled="pageType === 'edit' && isEditInput"
                                    placeholder="请输入"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="序列号" prop="serialNumber">
                                <el-input
                                    class="input-width-small"
                                    v-model="basicForm.serialNumber"
                                    placeholder="请输入"
                                ></el-input>
                            </el-form-item>

                            <el-form-item label="学生类型" prop="studentType">
                                <el-radio-group v-model="basicForm.studentType">
                                    <el-radio label="0">住宿</el-radio>
                                    <el-radio label="1">走读</el-radio>
                                    <el-radio label="2">半读</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item v-show="showCourseSelection" label="选科组合">
                                <el-select
                                    class="input-width-small"
                                    v-model="basicForm.courseSelection"
                                    :disabled="isHasStu && basicForm.courseSelection !== ''"
                                    placeholder="请选择"
                                    clearable
                                    filterable>
                                    <el-option
                                        v-for="item in courseSelectionList"
                                        :value="item.code"
                                        :label="item.name"
                                        :key="item.id"
                                    />
                                </el-select>
                                <div style="font-size: 12px">
                                    无组合可选？请点击
                                    <el-button
                                        type="text"
                                        @click="clickCourseSelection"
                                    >选科组合</el-button>
                                    开启状态
                                </div>
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </div>

            <!-- 展示或收起按钮 -->
            <!-- <div
                v-if="oriBasicForm.id"
                class="show-stow-btn"
                @click="showStowDetailForm">
                <span>添加更多信息({{ basicForm.archivesCompleteness || archivesCompleteness }}%)</span>
                <img v-show="detailFormShow" :src="triangleUp" alt="" />
                <img v-show="!detailFormShow" :src="triangleDown" alt="" />
            </div>
            <el-progress
                :percentage="basicForm.archivesCompleteness"
                :show-text="false"
            ></el-progress>
            <div
                v-if="oriBasicForm.id"
                v-show="detailFormShow"
                class="detail-form">
                <stu-info-fill
                    ref="stuInfoFill"
                    :mode="pageType"
                    :school-id="schoolId"
                    :stu-basic-info="oriBasicForm"
                    @getTeacherBaseInfo="getTeacherBaseInfo"
                />
            </div> -->
        </div>
        <!-- 上传头像 -->
        <dialog-wrapper
            :dialogObj="cropperDialogObj"
            @handleClose="handleCropperClose"
        >
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                    <vue-cropper
                        ref="cropper"
                        :img="option.img"
                        :info="option.info"
                        :fixed-box="option.fixedBox"
                        :can-scale="option.canScale"
                        :auto-crop="option.autoCrop"
                        :info-true="option.infoTrue"
                        :auto-crop-width="option.autoCropWidth"
                        :auto-crop-height="option.autoCropHeight"
                        :outputSize="option.size"
                        :high="false"
                    />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleCropperClose">取 消</el-button>
                <el-button :loading="cropperLoading" type="primary" @click="finish">确定</el-button>
            </span>
        </dialog-wrapper>
    </div>
</template>

<script>
// import { StuInfoFill } from "common-local";
import FormMainTitle from "../Sub/FormMainTitle/index.vue";
import Cascader from './SummaryStudentInfoCascader.vue'
import { auditStateConfig } from "@/assets/data/uploadImage";
import { VueCropper } from 'vue-cropper'
import { DialogWrapper } from "common-local";
import { CommonModel } from "@/models/Common";

export default {
    name: 'EditPageBase',
    components: {
        // StuInfoFill,
        FormMainTitle,
        Cascader,
        DialogWrapper,
        VueCropper
    },
    props: [
        'basicForm',
        'basicRules',
        'pageType',
        'action',
        'headers',
        'beforeBasicAvatarUpload',
        'handleBasicAvatarSuccess',
        'avatar',
        'showCourseSelection',
        'isHasStu',
        'item',
        'courseSelectionList',
        'archivesCompleteness',
        'triangleUp',
        'des',
        'triangleDown',
        'oriBasicForm',
        'detailFormShow',
        'schoolId',
        'OnlyNoMax',
        'headImgInfo',
    ],
    data() {
        return {
            auditStateConfig,
            isEditInput:false,
            consumNumber: null,
            cropperLoading: false,
            cropperDialogObj: {
                title: '裁剪图片',
                dialogVisible: false,
                width: '800px'
            },
            // 184
            option: {
                img: '', // 裁剪图片的地址
                file: '',
                info: false, // 裁剪框的大小信息
                fixedBox: true,
                autoCropWidth: 540,
                autoCropHeight: 720,
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                outputType: 'jpg',
                size: 0.9,
                consumNumber:''
            }
        }
    },
    filters: {},
    computed: {
    },
    watch: {
        consumNumber(newVal) {
        if (newVal) {
            this.isEditInput = true;
        }else{
            this.isEditInput = false;
        }
        console.log(this.isEditInput,'watch3333333333333333333');
        },
    },
    created() {
        console.log(this.basicForm,'this.basicForm.consumNumber')
        this.consumNumber = this.basicForm.consumNumber
    },
    mounted() {},
    methods: {
        changeCascader(data) {
            this.$emit('changeCascader', data);
        },
        stuNoChange(data) {
            this.$emit('stuNoChange', data);
        },
        clickCourseSelection() {
            this.$emit('clickCourseSelection');
        },
        showStowDetailForm() {
            this.$emit('showStowDetailForm');
        },
        getTeacherBaseInfo() {
            this.$emit('getTeacherBaseInfo')
        },
        handleFileChange(file) {
            let flag = this.beforeAvatarUpload(file);
            if (flag !== false) {
                this.$nextTick(() => {
                    // 上传预览图片  是个临时的缓存地址
                    this.option.img = URL.createObjectURL(file.raw);
                    this.option.file = file;
                    this.cropperDialogObj.dialogVisible = true;
                });
            }
        },
        beforeAvatarUpload(file) {
            const types = [".jpg", ".JPG", ".png", ".PNG", ".jpeg", ".JPEG", ".bmp", ".BMP", ".gif", ".GIF"];
            const isImg = types.includes(file.name.substring(file.name.lastIndexOf(".")));
            const isLt2M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            // 判断图像上传大小
            let riseze = file.size / 1024;
            console.log(riseze,'图片真实大小')
            if (riseze < 50) {
                this.$message.error("上传图片不能小于50kb!");
                return false;
            }

            if (riseze < 200) {
                console.log('图像小于200')
                this.option.size = 0.99;
            } else {
                this.option.size = 0.9;
                console.log('图像大于200')
            }

            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
                return false;
            }
            if (!isImg) {
                this.$message.error("上传图片不符合格式!");
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过20MB!");
                return false;
            }
        },
        changeFlag(){
            console.log(this.basicForm,'执行',this.consumNumber)
            this.consumNumber = this.basicForm.consumNumber
        },
        finish() {
            this.$refs.cropper.getCropBlob((data) => {
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onload = function() {
                    if (reader.readyState == 2) {
                        const img = new Image();
                        img.src = reader.result;
                        img.onload = function() {
                            console.log(img.width, img.height,'width-height')
                        };
                    }
                };

                this.cropperLoading = true;
                let newFile = new window.File([data], this.option.file.name, { type: data.type });
                console.log(newFile,'file-学生')
                let dataType = this.pageType === 'edit' ? "edit" : "add";
                let formData = new FormData();
                formData.append("file", newFile);
                formData.append('personType' ,'student');
                formData.append("dataType", dataType);
                if(this.pageType === 'edit'){
                    formData.append('personId', this.basicForm.id);
                }
                let commonModel = new CommonModel();
                commonModel.uploadByPeople(formData).then((res) => {
                    if (res.data.code === "200") {
                        this.handleBasicAvatarSuccess(res.data);
                        this.handleCropperClose();
                        this.setHeadImgInfo();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    setTimeout(() => {
                        this.cropperLoading = false;
                    }, 300);
                }).catch(() => {
                    setTimeout(() => {
                        this.cropperLoading = false;
                    }, 300);
                });
            });
        },
        handleCropperClose() {
            this.cropperDialogObj.dialogVisible = false;
        },
        handleUpload() {
            this.$refs.avatarUpload.$refs['upload-inner'].handleClick()
        },
        handleDelete() {
            this.basicForm.stuImg = ""
        },
        /**
         * @Description: 根据 keyName 获取对应状态下的值
         * @Author: 张冰华
         * @Date: 2024-08-29 11:45:18
         * @param { String } keyName
         * @returns { String } res auditStateConfig配置中对应的值
         */        
        getValueByState(keyName) {
            let auditState = this.headImgInfo.auditState;
            // let auditState = this.userInfo.auditState;
            if(!auditState) return ""
            let res = this.auditStateConfig.filter(item => item.state == auditState)[0][keyName];
            return res;
        },
        handleErrorTipClick() {
            this.$alert(this.headImgInfo.tip, '审核失败原因', {
                confirmButtonText: '确定',
                callback: action => {
                    
                }
            });
        },
        setHeadImgInfo() {
            let obj = { ...this.headImgInfo }
            obj.auditState = "0";
            this.$emit('setHeadImgInfo', obj)
        }
    }
}
</script>

<style lang="scss" scoped>
.common-form-view {
    text-align: left;

    .basic-avatar {
        .basic-avatar-div {
            width: 80px;
            height: 80px;
        }

        .basic-avatar-tip {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 1;
        }
    }

    ::v-deep .class-cascader {
        .el-col-20 {
            width: 100%;
        }
    }

    .basic-btn-div {
        text-align: center;
    }
}
.onlyCodeTip{
    color: #a6a6a6;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-top: 3px;
    line-height: 15px;
    max-width: 350px;
    .OnlyNoMaxSty{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    img{
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
}

.avatar-upload-wrap {
    display: flex;

    .avatar-upload {
        flex-shrink: 0;
        align-self: flex-start;
        width: 80px;
        height: 106px;
        border: 1px solid #d9d9d9;
        box-sizing: border-box;
        text-align: center;
        overflow: hidden;

        ::v-deep .el-upload--text {
            width: 80px;
            height: 106px;
        }

        .avatar-upload-img {
            position: relative;
            width: 80px;
            height: 106px;

            img {
                width: 100%;
                height: 100%;
            }

            .avatar-upload-img-operate {
                display: flex;
                cursor: default;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: rgba(0, 0, 0, 0.5);
                transition: opacity .3s;

                .el-icon-upload2, .el-icon-delete {
                    cursor: pointer;
                    color: #ffffff;
                    margin: 0 5px;
                    font-size: 18px;
                }
            }

            &:hover .avatar-upload-img-operate {
                opacity: 1;
            }

            .status-wrap {
                position: absolute;
                z-index: 10;
                bottom: 0px;
                display: flex;
                align-items: center;
                width: 98%;
                font-size: 12px;
                line-height: 12px;
                padding: 5px;
                .font_family {
                    margin-right: 5px;
                    font-size: 12px;
                }
            }
        }

        &-icon {
            font-size: 16px;
            color: #d9d9d9;
            line-height: 106px;
            text-align: center;
        }
    }

    .error-tip {
        width: 106px;
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 12px;
        color: #E64545;
        margin-top: 10px;
        cursor: pointer;
        .error-tip-text {
            width: 65%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        } 
    }

    .tips-wrap {
        margin-left: 24px;

        .tips-title {
            display: flex;
            align-items: center;
			.title {
                font-size: 14px;
                color: #333333;
                line-height: 20px;
            }
            .tip-text {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #E5A422;
                margin-left: 10px;
                i {
                    margin-right: 6px;
                }
            }
		}

        .tips-content {
            font-size: 12px;
            color: #999999;
            line-height: 20px;
        }
    }
}

.cropper-content {
    display: flex;
    justify-content: center;

    .cropper {
        // 413 626
        text-align: center;
        width: 540px;
        height: 720px;
    }

    ::v-deep .cropper-crop-box {
        background-color: #fff;
    }
}
</style>
